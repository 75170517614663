import {useLoaderData} from '@remix-run/react';
import {type LinksFunction} from '@remix-run/server-runtime';

import blogStylesheet from '@/stylesheets/enterprise/blog/styles.css?url';
import PageLayout from '@/components/shared/Page/PageLayout';
import Footer from '@/components/brochureV2/section/Footer/Footer';
import {BlogSubNav} from '@/pages/shopify.com/($locale)/enterprise/blog/components/BlogSubNav/BlogSubNav';
import {useTranslations} from '@/components/shared/Page/I18N';
import {ColorSchemeEnum} from '@/enums';
import BlogCard from '@/components/pages/blog/BlogCard/BlogCard';
import {BLOG_PAGE_TYPE, type Article} from '@/components/blog/types';
import {BlogConversion} from '@/components/blog/BlogConversion/BlogConversion';
import {FakeCursorPagination} from '@/components/pages/blog/FakeCursorPagination/FakeCursorPagination';
import {baseUri} from '@/constants';
import {EN_ENTERPRISE_BLOG_PATH} from '@/pages/shopify.com/($locale)/enterprise/blog/route';
import type {topicLoader} from '@/pages/shopify.com/($locale)/enterprise/blog/topics/$topic/server/topicLoader.server';
import {useBlogPageTitle} from '@/hooks/blog/useBlogPageTitle';

import TopicHeroSection from '../../components/TopicHeroSection/TopicHeroSection';

export {enterpriseHandle as handle} from '@/pages/shopify.com/($locale)/enterprise/blog/handle';
export {topicLoader as loader} from '@/pages/shopify.com/($locale)/enterprise/blog/topics/$topic/server/topicLoader.server';
export {combinedLeadAction as action} from '@/pages/shopify.com/($locale)/enterprise/blog/server/action.server';

export const links: LinksFunction = () => {
  return [{rel: 'stylesheet', href: blogStylesheet}];
};

export default function BlogTopic() {
  const {t} = useTranslations();

  const {articles, subNavTopics, topicContent, pagination, url}: any =
    useLoaderData<typeof topicLoader>();

  const currentURL = new URL(url, baseUri);
  const topicName = topicContent.title;
  const subTitle = topicContent.excerpt || topicContent?.description;
  const pageTitle = useBlogPageTitle(
    t('htmlHead.pageTitle', {topic: topicName}),
  );

  const heroArticles = articles.slice(0, 3);
  const articlesRest = articles.slice(3);

  return (
    <PageLayout
      title={pageTitle}
      metaDescription={t('htmlHead.metaDescription', {topic: topicName})}
      header={(props) => (
        <BlogSubNav
          {...props}
          subNavTopics={subNavTopics}
          enBlogPath={EN_ENTERPRISE_BLOG_PATH}
        />
      )}
      footer={<Footer colorScheme={ColorSchemeEnum.Dark} />}
      hasScrollRestoration
      isPureTitle
    >
      <div className="pt-36 bg-hero-dark-green-bg">
        <TopicHeroSection
          className="[&_h1]:text-left [&_h1]:tablet:text-center "
          subTitle={subTitle}
        >
          {topicName}
        </TopicHeroSection>
        <div className="bg-white">
          <div className="container pt-10 pb-28 desktop:pt-16">
            <div className="grid grid-cols-1 tablet:grid-cols-3 gap-4 desktop:gap-6 mb-8 tablet:mb-6 desktop:mb-12">
              {Boolean(heroArticles) &&
                heroArticles.map((article: Article, index: number) => (
                  <BlogCard
                    className="border-t border-shade-30 pt-4"
                    key={`grid-3-${index}`}
                    article={article}
                    enBlogPath={EN_ENTERPRISE_BLOG_PATH}
                    type="grid-3"
                    preferredTag={article.topicHandle}
                    blogType={BLOG_PAGE_TYPE.ENTERPRISE}
                    mode="light"
                  />
                ))}
            </div>
            <div className="grid grid-cols-1 tablet:grid-cols-4 desktop:grid-cols-4 gap-4 desktop:gap-6 tablet:gap-y-6 desktop:gap-y-9">
              {Boolean(articlesRest) &&
                articlesRest.map((article: Article, index: number) => (
                  <BlogCard
                    className="border-t border-shade-30"
                    key={`grid-4-${index}`}
                    article={article}
                    enBlogPath={EN_ENTERPRISE_BLOG_PATH}
                    preferredTag={topicName}
                    blogType={BLOG_PAGE_TYPE.ENTERPRISE}
                    mode="light"
                  />
                ))}
            </div>
            <FakeCursorPagination
              mode="enterprise"
              pagination={pagination}
              currentURL={currentURL}
            />
          </div>
        </div>
        <BlogConversion
          heading={t('sellAnywhere.headingHtml')}
          subhead={t('sellAnywhere.subheadHtml')}
          buttonHref={t('sellAnywhere.button.href')}
          buttonText={t('sellAnywhere.button.text')}
          mode="enterprise"
        />
      </div>
    </PageLayout>
  );
}
